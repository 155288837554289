@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
body {
  background: #101010;
  display: flex;
  flex-direction: column;
  font-family: 'Anonymous Pro';
}